@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  color: var(--tex-def-tie1);
}

.title {
  text-align: center;
  margin: 0;
}

.subTitle {
  text-align: center;
  margin: pxToRem(12) 0 0;
}

.content {
  text-align: center;
  margin: pxToRem(20) 0 pxToRem(24);
}

.footer {
  display: flex;
  justify-content: center;
}
