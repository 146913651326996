@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $zIndex-headerAbove;
  background: var(--bac-tie1);
  border-top: $border-0;
  box-shadow: var(--elevation-12dp);
  overflow: hidden;

  &:global(.carComparisonBanner--expanded) {
    .thumbnailsContainer {
      opacity: 0;
    }
    .bannerToggleIcon {
      transform: rotate(-180deg);
    }
  }
}

.header {
  height: pxToRem(56);
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: pxToRem(8);
}

.counter {
  background: var(--for-sec-per_pin);
  padding: pxToRem(2) pxToRem(6);
  border-radius: var(--cor_rad-lg);
  font-variant-numeric: tabular-nums;
}

.thumbnailsContainer {
  padding-left: pxToRem(8);
  transition: opacity ease-in-out 200ms;
}

.bannerToggle {
  position: absolute;
  top: 0;
  left: pxToRem(-20);
  width: calc(100% + #{pxToRem(40)});
  height: 100%;
  border: none;
  background: transparent;
  appearance: none;
  padding: 0;
  margin: 0;
}

.bannerToggleIcon {
  position: absolute;
  display: block;
  right: pxToRem(20);
  top: calc(50% - #{pxToRem(12)});
  transition: transform ease-in-out 300ms;
  width: pxToRem(24);
  height: pxToRem(24);
}

.carsWrapper,
.footer {
  display: grid;
  grid-column: 1fr;
  grid-row: auto;
  row-gap: pxToRem(8);
  padding: pxToRem(8) 0;
}

@include md {
  .container {
    display: grid;
    grid-template: '😀 🎛️' 1fr / 1fr 3fr;
  }
  .header {
    grid-area: 😀;
    align-self: center;
  }
  .thumbnailsContainer,
  .root:global(.carComparisonBanner--expanded) .bannerToggle {
    display: none;
  }

  .content {
    grid-area: 🎛️;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    column-gap: pxToRem(16);
  }

  .carsWrapper {
    grid-template-columns: 1fr 1fr;
    padding: pxToRem(16) 0;
    column-gap: pxToRem(16);
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    :global(.button) {
      min-width: pxToRem(140);
    }
  }
}

@include lg {
  .content {
    grid-template-columns: 2fr 1fr;
  }
}
