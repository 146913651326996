@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  container: summary / inline-size;
  padding: pxToRem(16);
  display: grid;
  border-radius: var(--cor_rad-lg);
  border: $border-0;
  box-shadow: var(--elevation-0dp);
  background: var(--bac-tie1);
  margin-bottom: pxToRem(56);
}

.imgContainer {
  display: flex;
  align-items: center;

  .icon {
    width: pxToRem(56);
    height: pxToRem(56);
  }
}

.container {
  display: flex;
  gap: pxToRem(24);
}

.rightContainer {
  padding-top: pxToRem(24);
}

.content {
  display: flex;
  flex-direction: column;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: pxToRem(12);
}

.formContainer {
  display: grid;
  width: 100%;
  padding: pxToRem(8);
  grid-template: 'a b' 1fr / 1fr pxToRem(96);
  overflow: hidden;
  background:
    url('/images/components/TradeIn/Screw.svg') no-repeat pxToRem(7) pxToRem(7),
    url('/images/components/TradeIn/Screw.svg') no-repeat pxToRem(7) calc(100% - #{pxToRem(7)}),
    url('/images/components/TradeIn/Screw.svg') no-repeat calc(100% - #{pxToRem(7)}) pxToRem(7),
    url('/images/components/TradeIn/Screw.svg') no-repeat calc(100% - #{pxToRem(7)}) calc(100% - #{pxToRem(7)});
  border-radius: var(--cor_rad-xs);
  position: relative;
  box-shadow:
    0 pxToRem(3) pxToRem(3) pxToRem(-2) #9693bd66,
    0 pxToRem(2) pxToRem(1) pxToRem(-1) #3e3c5e1a,
    pxToRem(1) pxToRem(1) pxToRem(1) 0 #ffffff40 inset,
    pxToRem(-1) pxToRem(-1) pxToRem(1) 0 #00000040 inset,
    0 0 0 pxToRem(3) #474747 inset;
  transition: box-shadow ease-in-out 200ms;

  &:focus-within {
    box-shadow:
      0 pxToRem(3) pxToRem(3) pxToRem(-2) #9693bd66,
      0 pxToRem(2) pxToRem(1) pxToRem(-1) #3e3c5e1a,
      pxToRem(1) pxToRem(1) pxToRem(1) 0 #ffffff40 inset,
      pxToRem(-1) pxToRem(-1) pxToRem(1) 0 #00000040 inset,
      0 0 0 pxToRem(3) #474747 inset,
      0 0 0 pxToRem(1.5) var(--bor-pri-mut);
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: pxToRem(3);
    bottom: pxToRem(3);
    right: pxToRem(104);
    border-left: thin solid var(--bor-neu-bas);
  }

  .input,
  .select {
    border: none;
    height: pxToRem(40);
    outline: none;
    background: transparent;
    box-sizing: border-box;
    appearance: none;
    padding: 0;
    margin: 0;
    transition: background ease-in-out 200ms;

    &:hover,
    &:focus-visible {
      background: var(--sta-on_bac-pre);
    }
  }

  .input {
    font-family: var(--font-barlow);
    text-transform: uppercase;
    font-size: pxToRem(30);
    width: 100%;
    text-align: center;
    border-radius: var(--cor_rad-2xs) 0 0 var(--cor_rad-2xs);
  }

  .select {
    padding: 0 pxToRem(24) 0 pxToRem(16);
    font-family: inherit;
    font-weight: bold;
    border-radius: 0 var(--cor_rad-2xs) var(--cor_rad-2xs) 0;
  }

  .iconCaret {
    position: absolute;
    right: pxToRem(12);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.error {
  color: var(--tex-err-tie1);
}

.button {
  width: 100%;
  height: pxToRem(40);
  @include fontSize(15, 18);
  font-weight: 600;
}

.recaptchaNotice {
  text-align: center;
  padding-top: pxToRem(8);
}

@include smp {
  .root {
    grid-template-areas:
      '🚗 📋'
      '🐝 🐝';
    grid-template-columns: 1fr pxToRem(360);
    column-gap: pxToRem(32);
  }

  .container {
    grid-area: 🚗;
    min-height: pxToRem(60);
    align-items: center;
  }

  .rightContainer {
    grid-area: 📋;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .formWrapper {
    display: grid;
    grid-template:
      '😀 🚗' auto
      '🔥 🔥' auto / 1fr pxToRem(56);
    column-gap: pxToRem(16);
    row-gap: 0;
    width: pxToRem(360);
  }

  .formContainer {
    grid-area: 😀;
  }

  .button {
    grid-area: 🚗;
  }

  .error {
    grid-area: 🔥;
    margin-top: pxToRem(12);
  }

  .imgContainer {
    align-items: start;
    height: pxToRem(60);
  }

  .recaptchaNotice {
    grid-area: 🐝;
    padding-top: pxToRem(32);
  }
}
