@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.switchWrapper {
  --shade-gradient-col1: #541ec8;
  --shade-gradient-col2: #311273;

  opacity: 0;
  transition: all ease-out 200ms;
  margin: 0;
  padding: pxToRem(8) pxToRem(16) pxToRem(8) pxToRem(8);
  width: pxToRem(162);
  gap: pxToRem(10);
  border-radius: pxToRem(20);
  color: var(--tex-inv-tie1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  box-sizing: border-box;
  height: pxToRem(40);
  cursor: pointer;
  position: relative;

  &.initialised {
    opacity: 1;
  }

  &:focus-within {
    box-shadow: 0 0 0 pxToRem(2) var(--bor-pri-mut);
  }

  &.active {
    --shade-gradient-col1: var(--for-suc-lig);
    --shade-gradient-col2: var(--gre-200);
    color: var(--tex-def-tie1);
    background: var(--for-suc-lig);

    :global(.shade:before) {
      opacity: 0;
    }

    .switch {
      background: var(--for-suc-bas);
    }

    .switchThumb {
      transform: translateX(pxToRem(20));
    }

    .iconOff {
      opacity: 0;
    }
    .iconOn {
      opacity: 1;
    }
  }
}

.checkbox {
  position: absolute;
  left: pxToRem(-40);
  opacity: 0;
}

.content {
  position: relative;
  pointer-events: none;
}

.switch {
  pointer-events: none;
  position: relative;
  width: pxToRem(44);
  height: pxToRem(24);
  border-radius: var(--cor_rad-md);
  font-size: pxToRem(20);
  transition: all ease-in-out 200ms;
  background: #ffffff33;

  .switchThumb {
    position: absolute;
    top: pxToRem(2);
    left: pxToRem(2);
    background: var(--for-neu-tie1);
    border-radius: var(--cor_rad-ful);
    transition: all ease-in-out 200ms;
    font-size: inherit;
    box-shadow: var(--elevation-0dp);
  }
}

.iconOff {
  color: var(--for-pri-bas);
}

.iconOn {
  color: var(--ico-suc-tie1);
  opacity: 0;
}
