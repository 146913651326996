@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;

.container {
  padding: 0;
}

.buttonLabel {
  vertical-align: -0.175em;
}

.buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleWrapper {
  display: flex;
  padding-bottom: pxToRem(24);
}

.titleIcon {
  margin-right: pxToRem(16);
  position: relative;
  width: pxToRem(36);
  height: pxToRem(36);
}

.iconWrapper {
  position: relative;
  width: pxToRem(24);
  height: pxToRem(24);
  margin-right: pxToRem(10);
}

.markdown {
  h6 {
    margin: 0;
  }
}

.documentWrapper {
  display: flex;
  flex-wrap: wrap;
}

.documentItem {
  padding: pxToRem(4);
  max-width: 100%;
  flex-basis: 100%;
  margin: 0;
}

.documentBtn {
  justify-content: start;
  padding: pxToRem(8);
}

@include sm {
  .documentItem {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
}

@include md {
  .container {
    padding: pxToRem(16);
  }
}
