@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

.root {
  @include fontSize(14, 18);
  color: var(--tex-def-tie3);

  a {
    color: inherit;
  }
}
