@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);
  position: relative;
  height: 100%;
}

.searchBarLayout {
  display: grid;
  gap: pxToRem(16);
}

.searchesContainer {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
  height: 100%;
}

.searchBarHeader {
  color: rgba(21, 13, 44, 0.38);
  margin-bottom: pxToRem(24);
  text-align: left;
}

.carouselHeader {
  color: var(--tex-def-tie1);
  text-align: left;
  margin: 0;
}

.search {
  flex-basis: calc(100% / 3);
}

@include sm {
  .root {
    gap: pxToRem(32);
  }

  .searchesContainer {
    flex-direction: row;
  }
}

@include md {
  .searchesContainer {
    flex-direction: column;
  }

  .root {
    gap: pxToRem(40);
  }
}
