@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  list-style: none;
  padding: 0 0 pxToRem(16);
  display: grid;
  margin: 0 0 pxToRem(20);
  gap: pxToRem(20);
  grid-auto-rows: auto;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@container (min-width: #{pxToRem(560)}) and (max-width: #{pxToRem(867)}) {
  .root {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@container (min-width: #{pxToRem(868)}) {
  .root {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@container (min-width: #{pxToRem(1280)}) {
  .root {
    &.watchlist {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    margin: 0 0 pxToRem(8);
  }
}
