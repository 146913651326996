@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;

.header {
  padding-bottom: pxToRem(24);
}

.title {
  margin: 0;
  text-align: center;
  @include fontSize(26, 32);
}

@include sm {
  .articles li:nth-child(3) {
    display: none;
  }
}

@include md {
  .articles li:nth-child(3) {
    display: block;
  }
}
