@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  padding: pxToRem(24);
  display: grid;
  grid-template:
    '🚗' auto
    '😀' 1fr
    '👠' auto / 1fr;
  row-gap: pxToRem(24);
  border-radius: var(--cor_rad-lg);
  background: var(--for-pri-lig);
  box-shadow: var(--elevation-2dp);
  height: 100%;

  &.noCta {
    grid-template:
      '🚗' auto
      '😀' 1fr / 1fr;
  }

  &.carouselTile {
    padding: pxToRem(24) pxToRem(16) pxToRem(16);
    row-gap: pxToRem(32);
    box-sizing: border-box;
    width: 100%;
  }
}

.imageContainer {
  grid-area: 🚗;
}

.contentContainer {
  grid-area: 😀;
}

.ctaContainer {
  grid-area: 👠;
  padding-top: pxToRem(8);

  &.carouselSpacing {
    margin-top: pxToRem(-10);
  }
}

.imageWrapper {
  height: pxToRem(100);
}

.image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  object-position: top left;
}

.description {
  margin-top: pxToRem(8);
}

@include mdp {
  .root {
    padding: pxToRem(32) pxToRem(24) pxToRem(24);
  }

  .logo {
    width: pxToRem(100);
    height: pxToRem(100);
    margin-bottom: pxToRem(5);
  }
}
