@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  background: var(--bac-tie1);
  padding: pxToRem(24);
  border-radius: var(--cor_rad-md);
  margin-top: pxToRem(24);
  border: thin solid var(--bor-neu-mut);
  box-shadow: var(--elevation-0dp);

  .icon {
    width: pxToRem(80);
    height: pxToRem(80);
    margin-bottom: pxToRem(24);
  }
}

.title {
  margin: 0 0 pxToRem(4);
}

.description {
  margin-bottom: pxToRem(43);
}

.cta {
  width: 100%;
}

@include sm {
  .root {
    padding: pxToRem(24);
    display: grid;
    grid-template-columns: pxToRem(80) auto pxToRem(160);
    column-gap: pxToRem(24);

    .icon {
      width: pxToRem(80);
      height: pxToRem(80);
      margin-bottom: 0;
    }
  }

  .title {
    margin-bottom: pxToRem(8);
  }

  .description {
    margin-bottom: 0;
  }

  .cta {
    max-height: pxToRem(56);
  }
}

@include md {
  .root {
    padding: pxToRem(16) pxToRem(24);
    column-gap: pxToRem(16);
    align-items: center;
  }
}
