@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.input {
  display: grid;
  width: 100%;
  h4 {
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.label {
  display: flex;
  p {
    @include fontSize(14, 18);
    color: var(--tex-def-tie2);
  }

  :global(.icon) {
    fill: var(--for-suc-bas);
  }
}

.content {
  display: grid;
  row-gap: pxToRem(15);
  width: 100%;
  padding: 0 pxToRem(8);
  strong {
    @include fontSize(15, 20);
    font-weight: 800;
    color: var(--tex-def-tie1);
  }

  p {
    @include fontSize(15, 20);
    color: var(--tex-def-tie2);
  }
}

.divider {
  border: $border-0;
  width: 100%;
}

.step3Radio {
  :global {
    .MuiFormControlLabel-label {
      //To override default styles from FormikInput.module.scss to remove redundant DOM element
      display: none !important;
    }
  }

  .error {
    &:global.MuiFormControlLabel-root:has(input:checked) {
      border: $border-3;
      box-shadow:
        var(--elevation-2dp),
        inset 0 0 0 pxToRem(2) $color-sentimental-error-600;

      &:hover {
        box-shadow:
          var(--elevation-2dp),
          inset 0 0 0 pxToRem(1) $color-uiPrimary-300;
      }
    }
  }
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addressNoteInput {
  margin-top: pxToRem(20);
  display: grid;
  row-gap: pxToRem(8);

  :global {
    .MuiFormControl-root {
      .MuiInputBase-root {
        padding-bottom: pxToRem(40);
      }
    }
  }
}

.greenText {
  margin-top: pxToRem(4);
  padding: pxToRem(12) pxToRem(12);
  background-color: var(--gre-050);
  border-radius: var(--cor_rad-xs);
  box-shadow: var(--elevation-0dp);
  @include fontSize(15, 20);
  color: var(--gre-600);
  p {
    color: var(--gre-600) !important;
  }
}

.icon {
  position: absolute;
  top: pxToRem(8);
  left: pxToRem(8);
  color: var(--for-suc-bas);
  background-color: var(--bac-tie1);
  border-radius: var(--cor_rad-ful);
  width: 100%;
  height: 100%;
}

.redText {
  background-color: rgba(255, 232, 235, 1);
  box-shadow: var(--elevation-0dp);
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(8) pxToRem(12);
  @include fontSize(15, 20);
  color: $color-sentimental-error-700;
  p {
    color: $color-sentimental-error-700 !important;
  }
}

.flexLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: pxToRem(16);
}

.iconContainer {
  width: pxToRem(52);
  height: pxToRem(52);
}
