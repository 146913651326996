@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;

.root {
  margin-bottom: pxToRem(56);
}

.header {
  margin: 0 0 pxToRem(24);
  color: var(--tex-def-tie1);
}

@include lg {
  .header {
    margin: 0 0 pxToRem(32);
  }
}
