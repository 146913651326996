@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/helpers' as *;

.root {
  display: grid;
  margin: auto;
  list-style: none;
  padding: initial;
  box-sizing: border-box;
  row-gap: pxToRem(48);
  column-gap: pxToRem(24);
}

@include sm {
  .root {
    grid-template-columns: 1fr 1fr;
    row-gap: pxToRem(32);
  }
}

@include md {
  .root {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
