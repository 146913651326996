@use 'sass:math';
@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  position: relative;
  height: 0;
  padding-bottom: percentage(math.div(9, 16));
  background: $color-neutral-200;
  border-radius: var(--cor_rad-xs);
  overflow: hidden;

  .controls {
    .controlsButton {
      background: $color-opacity-light-mediumEmphasis;
      position: absolute;

      &:hover,
      &:focus-visible {
        background: $color-uiPrimary-050;
      }
    }
  }
}

.playerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  isolation: isolate;
  border-radius: var(--cor_rad-xs);
  overflow: hidden;

  .interiorWrapper {
    display: none;
    width: 100%;
    height: 100%;
    &.active {
      display: block;
    }
  }
}

.heroImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controls {
  $spacingButton: pxToRem(8);

  .controlsButtonExpand {
    top: $spacingButton;
    left: $spacingButton;
  }

  .controlsButtonDoors {
    bottom: $spacingButton;
    left: $spacingButton;
  }

  .controlsButtonInterior {
    bottom: $spacingButton;
    right: $spacingButton;
  }

  .controlsButtonWatchlist {
    position: absolute;
    top: $spacingButton;
    right: $spacingButton;
  }

  .controlButtonDesktop {
    display: none;
  }
}

// Prevents browser rendering shift on some devices
.controlsButton,
.statusWrapper {
  transform: translate3d(0, 0, 0);
}

.statusWrapper {
  position: absolute;
  top: pxToRem(8);
  left: pxToRem(64);
  right: pxToRem(64);
  display: flex;
  justify-content: center;
}

.promoPill {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  div:first-child {
    position: relative;
  }
}

@include md {
  .controls {
    $spacingButton: pxToRem(16);

    .controlsButtonExpand {
      top: $spacingButton;
      left: $spacingButton;
    }

    .controlsButtonDoors {
      bottom: $spacingButton;
      left: $spacingButton;
    }

    .controlsButtonInterior {
      bottom: $spacingButton;
      right: $spacingButton;
    }

    .controlsButtonWatchlist {
      top: $spacingButton;
      right: $spacingButton;
    }

    .controlButtonMobile {
      display: none;
    }

    .controlButtonDesktop {
      display: flex;
    }
  }

  .statusWrapper {
    top: pxToRem(16);
  }
}
