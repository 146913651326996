@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
}

.usp {
  display: flex;
  flex-direction: column;
  gap: pxToRem(4);
  padding: 0 pxToRem(5);
}

.icon {
  width: pxToRem(44);
  height: pxToRem(44);
  margin: 0 auto;
}

.text {
  margin: 0;
  text-align: center;
  @include fontSize(15, 20);
  font-weight: 400;
  color: var(--tex-def-tie1);
  flex-grow: 1;
}

@include sm {
  .usp {
    max-width: pxToRem(150);
    margin: 0 auto;
    padding: 0 pxToRem(20);
  }
}
