@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

.root {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  // Gradient with colours from the cars photo background
  background: var(--gradient-product-placeholder);
  aspect-ratio: 16/9;
}
