@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  scroll-margin-top: pxToRem(134);
}

.modal:global(.modal) {
  max-width: pxToRem(682);
}

.formContent {
  .privacyCollectionNotice {
    margin-bottom: pxToRem(8);
    @include fontSize(14, 18);

    a {
      color: inherit;
      text-decoration: underline;
      font-weight: 800;
    }
  }

  .recaptchaNotice {
    @include fontSize(12, 16);
  }
}

.successBtn {
  width: 100%;
}

.messageInput {
  p {
    left: pxToRem(16);
  }
}

.checkboxContainer {
  margin-bottom: pxToRem(16);
}

.inputContainer {
  display: grid;
  grid-template:
    'fist_name last_name' auto
    'email email' auto
    'phone phone' auto / 1fr 1fr;
  column-gap: pxToRem(16);
}

.firstName {
  grid-area: fist_name;
}

.lastName {
  grid-area: last_name;
}

.emailAddress {
  grid-area: email;
}

.phoneNumber {
  grid-area: phone;
}

.recaptchaContainer {
  margin: pxToRem(16) 0 pxToRem(16);
}

.submitButton {
  width: 100%;
  padding: pxToRem(12);
}

.submitButtonWrapper {
  width: 100%;
}

.successMessageContainer {
  color: var(--tex-def-tie1);

  h6 {
    margin: pxToRem(14) 0 pxToRem(4);
    @include fontSize(20, 28);
    font-weight: 800;
  }

  p {
    margin: 0 0 pxToRem(24);
    @include fontSize(17, 24);
  }
}

@include sm {
  .inputContainer {
    grid-template-areas:
      'fist_name last_name'
      'email phone';
    column-gap: pxToRem(16);
  }

  .submitButtonWrapper {
    width: auto;
  }

  .submitButton {
    width: auto;
    padding: pxToRem(20) pxToRem(22);
  }

  .successBtn {
    width: auto;
  }

  .footerWrapper {
    width: auto;
  }

  .checkboxContainer {
    :global {
      .MuiFormControlLabel-root {
        align-items: center;
      }
      .MuiFormControlLabel-root:last-child {
        align-items: center;
        padding-top: pxToRem(0);
        .MuiCheckbox-root {
          padding: pxToRem(4) pxToRem(9);
        }
      }
    }
  }
}
