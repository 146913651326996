@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

//Shared header/description styles
@mixin headerStyleMobile {
  @include fontSize(20, 28);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

@mixin headerStyleDesktop {
  @include fontSize(26, 32);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

@mixin descriptionStyle {
  @include fontSize(17, 24);
  font-weight: 400;
  color: var(--tex-def-tie1);
}

.root {
  margin: auto;
  max-width: pxToRem(1104);
  padding-bottom: pxToRem(72);
}

.header {
  @include headerStyleMobile;
  text-align: center;
  margin-bottom: pxToRem(24);

  h1 {
    margin: 0;
  }
}

.pillsContainer {
  margin-bottom: pxToRem(32);
}

.carouselContainer {
  margin: 0 pxToRem(-16) pxToRem(48);
}

.allMatches {
  border-radius: var(--cor_rad-lg);
  background: var(--bac-tie1);
  padding: pxToRem(16);
  display: flex;
  flex-direction: column;
  gap: pxToRem(20);
}

.allMatchesHeader {
  @include headerStyleMobile;
}

.allMatchesDescription {
  @include descriptionStyle;
}

@include sm {
  .carouselContainer {
    margin-left: pxToRem(-32);
    margin-right: pxToRem(-32);
  }
}

@include md {
  .header {
    @include headerStyleDesktop;
    margin-bottom: pxToRem(32);
  }
  .pillsContainer {
    margin-bottom: pxToRem(64);
  }

  .carouselContainer {
    margin-bottom: pxToRem(64);
  }

  .allMatches {
    max-width: pxToRem(1103);
    margin: auto;
    padding: pxToRem(24);
    flex-direction: row;
    justify-content: space-between;
  }
}

@include mdp {
  .carouselContainer {
    margin-left: 0;
    margin-right: 0;
  }
}
