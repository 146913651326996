@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.container {
  display: grid;
  row-gap: pxToRem(8);

  :global {
    .MuiToggleButtonGroup-root {
      border-radius: pxToRem(42);
      height: pxToRem(32);
      background-color: var(--for-pri-sub);
      padding: 0 pxToRem(3);
      border-right: none;
      gap: pxToRem(4);
    }
  }
}

.buttonGroup {
  :global {
    .MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
      border-right: none;
      border-radius: pxToRem(24);
    }

    .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
      border-radius: pxToRem(24);
    }

    .MuiToggleButtonGroup-grouped {
      margin: auto;
      @include fontSize(15, 18);
      background-color: var(--for-pri-sub);
      color: var(--tex-def-tie1);
      font-weight: 600;
      height: pxToRem(26);
      text-transform: none;
      width: 100%;
      border: none;

      &.MuiToggleButton-root {
        border: none;

        &:hover,
        &:focus-visible {
          background-color: var(--sta-ter-hov);
        }

        &.Mui-selected {
          border-radius: pxToRem(24);
          box-shadow: 0 0 0 pxToRem(1) var(--bor-neu-bas);
          background-color: var(--bac-tie1);
          color: var(--tex-def-tie1);
          border: none;
          overflow: hidden;
          position: relative;

          &:hover,
          &:focus-visible {
            @include maskedBackground(var(--sta-sec-hov));
          }
        }
      }
    }
  }
}

.header {
  display: none;
}

@include sm {
  .header {
    display: unset;
    @include fontSize(17, 24);
    font-weight: 800;
    color: var(--tex-def-tie1);
  }
}
