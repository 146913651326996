@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  min-height: pxToRem(76);
  border: $border-0;
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(4);
  position: relative;
}

.rootMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bac-tie2);
  color: var(--tex-def-tie3);

  &.rootMessageError {
    background: var(--for-err-lig);
    color: var(--tex-err-tie2);
  }
}

.rootTile {
  display: grid;
  grid-template:
    '🚗 😀' auto
    '🚗 🎛️' auto
    '🚗 💰' 1fr / pxToRem(120) 1fr;
  column-gap: pxToRem(12);
}

.carImage {
  grid-area: 🚗;
  border-radius: calc(var(--cor_rad-xs) - #{pxToRem(4)});
  overflow: hidden;
  display: block;
  background: var(--gradient-product-placeholder);
  width: pxToRem(120);
  height: pxToRem(66);
}

.carName {
  grid-area: 😀;
  padding-top: pxToRem(4);
}

.carKms {
  grid-area: 🎛️;
  color: var(--tex-def-tie2);
}

.carPrice {
  grid-area: 💰;
  align-self: center;
  padding-bottom: pxToRem(4);
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--cor_rad-xs);

  :global(.shade:before) {
    content: none;
  }
}

.removeButton {
  position: absolute;
  top: pxToRem(-8);
  left: pxToRem(-8);
}
