@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.headerContainer {
  display: flex;
  gap: pxToRem(16);
  justify-content: space-between;
  align-items: center;
}
.financeQuotesHeader {
  margin: 0;
  @include fontSize(26, 32);
  font-weight: 800;
}

.financeQuotesDescription {
  margin: pxToRem(16) 0 0;
  @include fontSize(15, 20);
  font-weight: 400;
  color: var(--tex-def-tie1);
}

.quoteCardContainer {
  margin: pxToRem(32) 0;
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
}

.importantInformation {
  p {
    margin: 0;
    @include fontSize(14, 18);
    color: var(--tex-def-tie1);
  }
}

.blockContainer {
  display: grid;
  grid-auto-flow: row;
  gap: pxToRem(16);
}

.separator {
  margin: pxToRem(32) 0;
  padding: 0;
  border: none;
  border-top: $border-0;
}

.icon {
  svg {
    width: pxToRem(40);
    height: pxToRem(40);
  }
}

.content {
  margin-top: pxToRem(8);
  @include fontSize(17, 24);
  font-weight: 400;
  color: var(--tex-def-tie1);
}

.contact {
  text-align: center;
}

.contactHeader {
  margin-bottom: pxToRem(8);
}

.contactTitle {
  @include fontSize(32, 40);
  font-weight: 800;
  margin: 0;
}

.contactContent {
  @include fontSize(17, 24);
}

.contactFooter {
  margin-top: pxToRem(24);
}

.groupBtnWrapper {
  display: grid;
  gap: pxToRem(16);
  margin: 0 auto;
  width: 100%;
}

.button {
  width: 100%;
  border-radius: var(--cor_rad-xs);
  background-color: var(--bac-tie1);
  padding: pxToRem(16);
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: pxToRem(16);
  text-decoration: none;
  color: $color-uiPrimary-600;

  &:hover {
    background-color: $color-neutral-100;
  }
  &:active {
    background-color: $color-neutral-200;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: pxToRem(8);
  border-radius: 50%;
  background-color: $color-neutral-200;
}

.btnLabelWrapper {
  display: flex;
  flex-flow: column;
  text-align: left;
}

.btnLabel {
  font-weight: 800;
  color: var(--tex-def-tie1);
  margin-bottom: pxToRem(2);
  @include fontSize(20, 28);
}

.btnText {
  font-weight: 400;
  @include fontSize(17, 24);

  &.btnEmText {
    font-weight: 800;
    text-decoration: underline;
  }
}

.noFinanceQuote {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  margin-top: pxToRem(24);
  margin-bottom: pxToRem(48);
}

.noFinanceQuoteContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: pxToRem(610);
}

.noFinanceQuoteTitle {
  text-align: center;
  font-weight: 800;
  @include fontSize(17, 24);
}

.noFinanceQuoteDescription {
  text-align: center;
  @include fontSize(15, 20);
}

@include sm {
  .blockContainer {
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    gap: pxToRem(24);
    margin-bottom: pxToRem(32);
  }

  .groupBtnWrapper {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row;
  }

  .button {
    gap: pxToRem(8);
  }

  .btnLabel {
    @include fontSize(17, 24);
  }

  .btnText {
    @include fontSize(15, 20);
  }
}

@include md {
  .button {
    gap: pxToRem(16);
  }

  .btnLabel {
    @include fontSize(20, 28);
  }

  .btnText {
    @include fontSize(17, 24);
  }

  .noFinanceQuote {
    margin-top: pxToRem(32);
    margin-bottom: pxToRem(64);
  }
}
