@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  container: card / inline-size;

  .card {
    border: none;
    padding: pxToRem(2);
  }
}

.separator {
  margin: 0;
  padding: 0;
  border: none;
  border-top: $border-0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: pxToRem(36);
  padding: pxToRem(20);
  border: $border-0;
  box-shadow: var(--elevation-0dp);
  border-radius: var(--cor_rad-lg);
}

.contentContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: pxToRem(24);
}

.content {
  display: flex;
  gap: pxToRem(12);
}

.valueText {
  margin: 0;
  color: var(--tex-def-tie1);
}

.reviewsContainer {
  display: grid;
  row-gap: pxToRem(16);
  width: 100%;
}

.layout {
  display: flex;
  flex-direction: row;
}

.summary {
  display: flex;
  gap: pxToRem(8);
  margin: auto;
}

.imageContainer {
  display: flex;
  align-items: center;
}

.avgRating {
  @include fontSize(15, 20);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.totalReviews {
  @include fontSize(15, 20);
  font-weight: 400;
  color: var(--tex-def-tie1);
}

.buttonContainer {
  margin: auto;

  :global {
    .MuiButton-label {
      @include fontSize(15, 20);
      font-weight: 800;
    }
  }
}

@container (min-width: #{pxToRem(704)}) {
  .container {
    flex-direction: row;
    justify-content: space-around;
    gap: pxToRem(20);
  }

  .contentContainer {
    grid-template-columns: repeat(2, pxToRem(162));
    gap: pxToRem(16) pxToRem(8);
  }

  .containerFull {
    grid-template-columns: repeat(2, 1fr);
    gap: pxToRem(24);
  }

  .separator {
    border: none;
    border-left: $border-0;
  }
}
