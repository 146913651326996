@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: pxToRem(8);
}

.message {
  @include fontSize(17, 24);
}

.actionButton {
  font-weight: 600;
  padding: pxToRem(8);
  min-height: var(--minHeight-xs);
}
