@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.chip {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--cor_rad-lg);
  font-weight: 900;
  @include fontSize(12, 16);
  text-transform: uppercase;
  letter-spacing: pxToRem(1.2);
  color: var(--tex-def-tie1);
  background-color: var(--for-neu-tie3);
  padding: 0 pxToRem(12);
  height: pxToRem(24);
  width: fit-content;
  min-width: pxToRem(97);

  &.required {
    color: var(--tex-cau-tie1);
    background-color: var(--for-cau-lig);
  }

  &.complete {
    color: var(--tex-suc-tie2);
    background-color: var(--for-suc-lig);
  }
}

.fieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
}

.modalTitleWrapper {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
}

.header {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
}

.footer {
  border-top: $border-0;
  width: 100%;
  display: flex;
  justify-content: right;
}

.groupedImageSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--cor_rad-xs);
  background-color: var(--bac-tie1);
  padding: pxToRem(16);
  flex-wrap: wrap;
  gap: pxToRem(16);
  border: pxToRem(1) solid $color-neutral-100;

  &.complete {
    background-color: var(--gre-050);
    border-color: var(--bor-suc-bas);
    box-shadow: inset 0 0 0 pxToRem(1) var(--bor-suc-bas);
  }

  &.error {
    border-color: var(--bor-err-bas);
    box-shadow: inset 0 0 0 pxToRem(1) var(--bor-err-bas);
  }

  .title {
    @include fontSize(17, 24);
    font-weight: 800;
    color: var(--tex-def-tie1);
  }

  .text {
    @include fontSize(14, 18);
    letter-spacing: 0.2px;
    text-wrap: normal;
  }
}

.fieldError {
  color: var(--tex-err-tie1);
}

.formContent {
  display: flex;
  flex-direction: column;
}

.start {
  display: flex;
  gap: pxToRem(8);
}

.end {
  display: flex;
  align-items: center;
  gap: pxToRem(8);
  width: 100%;
  justify-content: space-between;
}

.buttonWrapper {
  display: flex;
  padding: pxToRem(20) 0 0;
  justify-content: right;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

@include sm {
  .start {
    flex: 2;
  }
  .end {
    justify-content: flex-end;
    flex: 1;
  }
}
