@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: grid;
  grid-template:
    '🍞 🍞' auto
    '😀 😀' auto
    '👔 👔' auto
    '🎛 🎛 ' auto / 1fr 1fr;
  column-gap: pxToRem(8);
  margin-bottom: pxToRem(24);
  position: relative;
  z-index: 2; // Higher than PLPFilterChipsWrapper

  .description {
    padding-bottom: pxToRem(16);
    margin-bottom: pxToRem(16);
    border-bottom: thin solid var(--bor-neu-mut);
  }
}

.breadcrumbs {
  grid-area: 🍞;
  margin-bottom: pxToRem(16);
}
.titleContainer {
  grid-area: 😀;
  margin-bottom: pxToRem(16);

  &:last-child {
    padding-bottom: 0;
  }
}
.description {
  grid-area: 👔;

  p {
    margin-bottom: pxToRem(8);

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
  }

  hr {
    border-width: thin 0 0 0;
    border-style: solid;
    border-color: var(--bor-neu-mut);
  }
}

.filtersSortWrapper {
  grid-area: 🎛;
  height: 40px;
}

@include md {
  .root {
    display: grid;
    grid-template:
      '🍞 🍞 🍞' auto
      '😀 😀 🎛' auto
      '👔 👔 👔' auto / 1fr auto auto;
    column-gap: pxToRem(24);
  }

  .description {
    margin-bottom: 0;
  }

  .titleContainer {
    align-self: center;
  }
}

@include mdp {
  .root {
    padding-top: pxToRem(8);
  }

  .searchFilters {
    display: none;
  }

  .locationControls,
  .searchSort {
    padding-top: 0;
  }

  .title {
    @include fontSize(32, 40);
  }

  .titleContainer {
    padding-bottom: 0;
  }

  .titleContainer,
  .locationControls,
  .searchSort {
    align-self: center;
  }

  .description {
    padding-top: pxToRem(8);
  }
}
