@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  @include sectionContentHub();
  padding: 0 0 pxToRem(24);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: pxToRem(24);
    width: calc(100% - #{pxToRem(40)});
    height: 0;
    left: pxToRem(20);
    border-top: $border-0;
  }
}

@include md {
  .root {
    padding: 0 pxToRem(20) pxToRem(56);

    &::after {
      content: none;
    }
  }
}
