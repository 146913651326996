@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.inputContainer {
  &.hasImagePreview {
    width: unset;
  }
  width: 100%;
}

.boxesContainer {
  display: flex;
  width: 100%;
  gap: pxToRem(12);
  align-items: center;
  flex-wrap: wrap;
  margin-top: pxToRem(8);
}

.imagePreviewContainer {
  width: pxToRem(76);
  height: pxToRem(76);
  border-radius: var(--cor_rad-xs);
  position: relative;
  object-fit: cover;
  overflow: hidden;
}

.image {
  width: pxToRem(76);
  height: pxToRem(76);
  object-fit: cover;
  display: block;
}

.isPending {
  opacity: 0.1;
}

.removeLoadingWrapper {
  position: absolute;
  width: pxToRem(20);
  height: pxToRem(20);
  top: pxToRem(8);
  right: pxToRem(8);
}

.removeButton {
  position: absolute;
  width: pxToRem(20);
  height: pxToRem(20);
  top: pxToRem(8);
  right: pxToRem(8);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-uiPrimary-600;
  border: none;

  svg {
    fill: white;
    width: pxToRem(16);
    height: pxToRem(16);
  }
}

.noImagePreviewContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: pxToRem(8);
}

.leftRow {
  display: flex;
  align-items: center;
  gap: pxToRem(8);
}

.content {
  position: relative;
  border: 2px dashed $color-neutral-400;
  border-radius: var(--cor_rad-xs);
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  &.hasImagePreview {
    width: pxToRem(76);
    height: pxToRem(76);
  }
}

.placeholderIcon {
  margin: pxToRem(16) 0 pxToRem(16) pxToRem(8);
  display: flex;
  align-items: center;
  justify-content: center;
  fill: none;
  svg {
    width: pxToRem(72);
    height: pxToRem(72);
  }
}

.uploadIconAlone {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  border: $border-4;
}

.placeholderContent {
  padding: pxToRem(20);
  display: flex;
}

.uploadIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: pxToRem(16);
}

.placeholderTextWrapper {
  display: flex;
  flex-direction: column;
}

.placeholderHeading {
  @include fontSize(15, 20);
  color: var(--tex-def-tie2);
}

.placeholderText {
  @include fontSize(14, 18);
  letter-spacing: pxToRem(0.2);
  color: var(--tex-def-tie3);
}

.button {
  margin: pxToRem(16);
  padding: pxToRem(12) pxToRem(16);
}

.label {
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.errorMessage {
  @include fontSize(14, 18);
  color: var(--tex-err-tie1);
  margin: pxToRem(4) 0 0;
}

.fieldError {
  color: var(--tex-err-tie1);
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

@include sm {
  .imagePreviewContainer {
    width: pxToRem(90);
    height: pxToRem(90);
  }

  .image {
    width: pxToRem(90);
    height: pxToRem(90);
  }

  .content {
    &.hasImagePreview {
      width: pxToRem(90);
      height: pxToRem(90);
    }
  }
}
