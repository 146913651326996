@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  display: block;
  position: relative;
  width: pxToRem(72);
  height: pxToRem(40);
  isolation: isolate;

  .thumbnail:first-child {
    z-index: 1;
  }

  &.rootThumbnails2 {
    .thumbnail:first-child {
      transform: rotate(-4deg) translate3d(pxToRem(-2), pxToRem(-2), 0);
    }
    .thumbnail:last-child {
      transform: rotate(4deg) translate3d(pxToRem(1), pxToRem(-2), 0);
    }
  }
}

.thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--gradient-product-placeholder);
  overflow: hidden;
  border-radius: pxToRem(4);
  border: pxToRem(2) solid var(--bac-tie1);
  transition: transform ease-in-out 150ms;
  transform-origin: bottom left;
  box-shadow: var(--elevation-2dp);
}

.thumbnailImage {
  display: block;
}
