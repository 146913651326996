@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  padding-top: pxToRem(24);
  display: block;
  text-align: center;

  .image {
    display: inline-block;
    width: pxToRem(297);
    height: pxToRem(210);
  }
}

.footer {
  margin-top: pxToRem(24);
}
