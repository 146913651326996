@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    min-width: pxToRem(40);

    &.currentPage:global(.button) {
      --shade-gradient-col1: #541ec8;
      --shade-gradient-col2: #311273;
      color: var(--tex-inv-tie1);

      :global(.shade),
      :global(.shade:before) {
        display: block;
      }

      &:hover,
      &:focus,
      &:active {
        color: var(--tex-inv-tie1);
      }
    }
  }
}

.pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  column-gap: pxToRem(2);
}

.ellipsis {
  min-width: pxToRem(40);
  text-align: center;
  @include fontSize(17, 24);
  font-weight: 400;
}

.buttonPrevNext {
  &:disabled {
    opacity: 0.5;
  }
}

.buttonPrevNextContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonPrevNextText {
  display: none;
  font-weight: 600;
}

@include sm {
  .pagination {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 50vw;
      border-top: $border-0;
    }

    &:before {
      right: calc(100% + pxToRem(12));
    }

    &:after {
      left: calc(100% + pxToRem(12));
    }
  }

  .buttonPrevNext {
    &:first-child {
      margin-right: pxToRem(10);
    }
    &:last-child {
      margin-left: pxToRem(10);
    }

    .buttonPrevNextIcon {
      display: none;
    }
  }
  .buttonPrevNextText {
    display: block;
  }
}
