@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  :global {
    .modalContentMain {
      padding-top: 0;
    }

    .modalHeader {
      padding: pxToRem(16) pxToRem(12);

      div h2 {
        @include fontSize(21, 28);
      }
    }
  }
}

.container {
  background-color: var(--bac-tie1);
}

.header {
  display: flex;
  justify-content: space-between;
  padding: pxToRem(20) pxToRem(24);
  align-items: center;
}

.contentWrapper {
  margin: 0 0 pxToRem(-16);
}

.content {
  display: grid;
  row-gap: pxToRem(20);
  padding: pxToRem(24);
  background-color: var(--bac-tie2);
  border-radius: var(--cor_rad-lg);
}

.detailedBlock {
  display: flex;
  justify-content: space-between;
}

.priceLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}

.bold {
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.description {
  @include fontSize(15, 20);
  font-weight: 400;
  color: var(--tex-def-tie1);
}

.price {
  @include fontSize(17, 24);
  font-weight: 400;
  color: var(--tex-def-tie1);
  text-align: right;
}

.total {
  @include fontSize(20, 28);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.textPadding {
  padding-right: pxToRem(20);
  @include fontSize(20, 28);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.divider {
  width: 100%;
  border: $border-0;
  margin: 0;
}

.introducerFeeTitleBlock {
  display: flex;
  align-items: center;
}

.introducerFeeBlock {
  text-align: center;
}

.contentHeader {
  display: flex;
  margin-bottom: pxToRem(8);
  justify-content: center;
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
}

.logoImg {
  padding: pxToRem(7.5) pxToRem(2.5);
  line-height: 0;
}

.discountContainer {
  display: flex;
  margin-left: pxToRem(4);
  padding: pxToRem(4) pxToRem(12);
  background: $color-brandPrimary-100;
  color: var(--tex-inv-tie1);
  border-radius: pxToRem(29);
  text-transform: uppercase;

  p {
    margin: 0;
    @include fontSize(12, 16);
    font-weight: 900;
    letter-spacing: pxToRem(1.2);
  }
}

.oldValues {
  text-decoration: line-through;
  font-weight: 400;
}

.discountValue {
  @include fontSize(15, 20);
  font-weight: 800;
  color: $color-brandPrimary-100;
  margin-left: pxToRem(4);
}

@include sm {
  .discountContainer {
    margin-left: pxToRem(8);
  }

  .contentWrapper {
    padding-bottom: pxToRem(10);
  }

  .root {
    :global {
      .modalHeader {
        div h2 {
          display: flex;
          align-items: center;
          height: pxToRem(40);
          @include fontSize(26, 32);
        }
      }

      .modalContentMain {
        padding-top: pxToRem(8);
      }
    }
  }
}
