@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.fieldsWrapper {
  display: flex;
  flex-flow: column;
  gap: pxToRem(16);
}

.tipsWrapper {
  margin-top: pxToRem(24);
  margin-bottom: pxToRem(24);
}

.tagHolder {
  padding-top: pxToRem(4);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: pxToRem(6);
}

.tag {
  border-radius: pxToRem(99);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ced8dd;
  padding: 0 pxToRem(8);
  height: pxToRem(28);
  @include fontSize(12, 16);
  letter-spacing: pxToRem(1.2);
  text-transform: uppercase;
  font-weight: 900;
  color: var(--tex-def-tie1);
}

.leftWell {
  display: none;
}

.qrWrapper {
  width: pxToRem(108);
  height: pxToRem(108);
  padding: pxToRem(8);
  background-color: var(--bac-tie1);
  border: $border-2;
  border-radius: var(--cor_rad-xs);
  display: flex;
  justify-content: center;
  align-items: center;
}
.descWrapper {
  margin-top: pxToRem(8);
  overflow: hidden;
  h5 {
    margin: 0;
    margin-bottom: pxToRem(4);
    color: var(--tex-def-tie2);
    @include fontSize(17, 24);
    font-weight: 800;
  }
  p {
    color: var(--tex-def-tie2);
    @include fontSize(14, 18);
  }
}

.rightWell {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  gap: pxToRem(16);
  padding: pxToRem(16);
  border: 1px solid $color-uiPrimary-050;
  border-radius: var(--cor_rad-xs);
  h5 {
    margin: 0;
    color: var(--tex-def-tie2);
    @include fontSize(17, 24);
    font-weight: 800;
  }
  button.link {
    margin: 0 !important;
    padding: 0 !important;
    margin-right: auto !important;

    span {
      color: $color-uiPrimary-600;
      font-weight: 800;
      text-align: left;
      @include fontSize(14, 18);
    }
  }
  p {
    color: var(--tex-def-tie2);
    @include fontSize(14, 18);
  }
}

@include md {
  .root {
    grid-template-rows: minmax(0, 1fr) pxToRem(96);
  }

  .streamliningSection {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: pxToRem(20);
  }

  .leftWell {
    display: flex;
    gap: pxToRem(20);
    padding: pxToRem(8);
    border: 1px solid $color-uiPrimary-050;
    border-radius: var(--cor_rad-xs);
  }
}
