@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: pxToRem(36);
  background: var(--bac-tie1);
  padding: pxToRem(24);
  border-radius: var(--cor_rad-md);
  margin-top: pxToRem(24);
  border: thin solid var(--bor-neu-mut);
  box-shadow: var(--elevation-0dp);
  transition: background ease-in-out 200ms;
}

.content {
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);

  h5 {
    margin: 0 0 pxToRem(4);
  }

  p {
    margin: 0;
    @include fontSize(17, 24);
  }

  .qualityImg {
    width: pxToRem(80);
    height: pxToRem(80);
  }
}

.searchToggle {
  max-width: max-content;
}

@include sm {
  .container {
    flex-direction: row;
  }

  .content {
    flex-direction: row;
    flex: 1;
    align-items: flex-start;

    .qualityImg {
      width: pxToRem(64);
      height: pxToRem(64);
    }
  }
}

@include md {
  .content {
    h5 {
      margin: 0 0 pxToRem(8);
    }
  }
}
