@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.buttonDismiss {
  &:global(.button) {
    --shade-gradient-col1: var(--red-300);
    --shade-gradient-col2: var(--red-200);
    color: var(--ico-err-tie2);
  }
}
