@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

$textHorizontalPadding: pxToRem(4);
$footerActionHeight: pxToRem(48);

.root {
  position: relative;
  margin: 0;
  isolation: isolate;
  background: var(--for-neu-tie1);
  border-radius: var(--cor_rad-lg);
  box-shadow: var(--elevation-2dp);
  transition: box-shadow 200ms ease-in-out;
  border: thin solid var(--bor-neu-mut);
  padding: pxToRem(12);
  box-sizing: border-box;
  display: grid;
  grid-template:
    '🚗 🚗' auto
    '😀 ❤️' auto
    '🔎 ❤️' auto
    '🎛️ ❤️' auto
    '👠 👠' 1fr / 1fr pxToRem(56);
  height: 100%;

  &.plp {
    padding-bottom: 0;

    &:hover,
    &:focus-within {
      box-shadow: var(--elevation-12dp);
    }

    .link {
      height: calc(100% - $footerActionHeight);
      border-radius: var(--cor_rad-lg) var(--cor_rad-lg) 0 0;
    }

    .footer {
      padding-bottom: 0;
    }
  }

  &.loading {
    box-shadow: var(--elevation-0dp);
    &:hover,
    &:focus-within {
      box-shadow: var(--elevation-0dp);
    }
  }

  .image {
    grid-area: 🚗;
    width: 100%;
    height: auto;
    margin-bottom: pxToRem(16);
    border-radius: var(--cor_rad-xs);
  }
}

.link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: inherit;
  cursor: pointer;
  overflow: hidden;

  :global(.shade::before) {
    content: none;
  }
}

.imagePlaceholder {
  aspect-ratio: 16/9;
  background: var(--gradient-product-placeholder);
}

.model,
.variant,
.details {
  padding-left: $textHorizontalPadding;
}

.model,
.variant {
  text-transform: uppercase;
}

.model {
  grid-area: 😀;
  padding-bottom: pxToRem(2);
}

.variant {
  grid-area: 🔎;
  color: var(--tex-def-tie2);
  padding-bottom: pxToRem(2);
}

.details {
  grid-area: 🎛️;
  color: var(--tex-def-tie2);
  padding-bottom: pxToRem(12);
}

.statusPillWrapper {
  position: absolute;
  top: pxToRem(20);
  left: pxToRem(20);
}

.controls {
  grid-area: ❤️;
  justify-self: flex-end;
  padding-right: $textHorizontalPadding;
}

.watchlist {
  position: relative;
  z-index: 2;
}

.footer {
  grid-area: 👠;
  padding: 0 $textHorizontalPadding pxToRem(4);
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

.repayment {
  color: var(--tex-def-tie2);

  p {
    display: inline;
  }
}

.watchers {
  background: var(--for-neu-tie2);
  border-radius: var(--cor_rad-2xs);
  width: fit-content;
  padding: pxToRem(4) pxToRem(8);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: pxToRem(16);
  column-gap: pxToRem(4);

  :global(.icon) {
    font-size: pxToRem(16);
  }
}

.footerActions {
  margin: pxToRem(16) pxToRem(-16) 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: $border-0;
  height: $footerActionHeight;

  .footerActionButton:global(.button) {
    --shade-gradient-col1: var(--sta-ter-hov);
    --shade-gradient-col2: var(--sta-ter-def);
    border: none;
    border-right: $border-0;
    border-radius: 0;
    min-height: $footerActionHeight;
    color: inherit;
    font-weight: normal;

    &:first-child {
      border-radius: 0 0 0 var(--cor_rad-lg);
    }
    &:last-child {
      border-radius: 0 0 var(--cor_rad-lg) 0;
      border-right: none;
    }
  }
}

.footerSeparatorContent {
  display: flex;
  align-items: center;
  column-gap: 0.2em;
}
