@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .select {
    padding: pxToRem(8) pxToRem(40) pxToRem(8) pxToRem(14);
    line-height: 1;
    pointer-events: auto;
  }
}

.label {
  white-space: nowrap;
}

.selectWrapper {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: var(--cor_rad-md);

  :global(.shade) {
    pointer-events: none;
  }

  :global(.shade:before) {
    display: none;
  }
}

.select {
  appearance: none;
  display: block;
}

.iconWrapper {
  $iconSize: 24;
  width: pxToRem($iconSize);
  height: pxToRem($iconSize);
  margin-left: pxToRem(-$iconSize);
  transform: translateX(pxToRem(-8));
  position: relative;
  display: block;
  pointer-events: none;
  color: $color-uiPrimary-600;
}
