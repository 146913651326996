@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: pxToRem(8);
  position: relative;
  text-decoration: none;
  padding: pxToRem(16);
  border-radius: var(--cor_rad-lg);
  border: $border-0;
  box-shadow: var(--elevation-2dp);
  overflow: hidden;
  :global(.shade::before) {
    content: none;
  }
}
.searchContent {
  display: grid;
  pointer-events: none;
}

.searchTitle {
  color: var(--tex-def-tie1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.searchCaption {
  color: rgba(21, 13, 44, 0.7);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.searchIconsContainer {
  pointer-events: none;
}

.icon {
  width: pxToRem(24);
  height: pxToRem(24);
  margin: auto;
  color: var(--tex-def-tie1);
}
