@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  min-height: pxToRem(280);

  // Allow the modal to display above the checkout header
  &:global.MuiDialog-root {
    z-index: 1400 !important;
  }

  :global {
    .modalContent {
      min-height: pxToRem(280);

      .modalContentMain {
        padding: 0 pxToRem(20) pxToRem(20);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}

.footer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  column-gap: pxToRem(8);
}

.btn {
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(16) pxToRem(20);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: none;
  font-family: var(--font-default);
  @include fontSize(17, 24);
  font-weight: 600;
}

.btnContinue {
  background-color: $color-uiPrimary-600;
  color: var(--tex-inv-tie1);
  &:hover {
    background-color: $color-uiPrimary-700;
  }
}

.btnGoBack {
  border: $border-2;
  color: var(--tex-def-tie1);
  background-color: var(--bac-tie1);
  &:hover {
    background-color: var(--bac-tie2);
  }
}
