@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  width: pxToRem(48);
  height: pxToRem(48);
  position: relative;
  overflow: hidden;

  img {
    object-fit: contain;
  }
}

.circle {
  border-radius: 50%;
}

.gradient {
  background: linear-gradient(149.13deg, #fffedd 7.12%, #ffcbc4 92.07%);
}

.illustration {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
