@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.header {
  margin-bottom: pxToRem(32);
}

@include md {
  .header {
    margin-bottom: pxToRem(40);
  }
}
