@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: grid;
  width: 100%;
  grid-template:
    '💰 🌅' auto
    '🎛 🎛' auto
    '🔍 🔍' auto / 1fr auto;
  row-gap: pxToRem(4);

  &.unavailable {
    .quote {
      color: var(--tex-def-tie1);
      @include fontSize(17, 24);
    }
    .fees {
      display: block;
      font-weight: normal;
      @include fontSize(14, 18);
      letter-spacing: pxToRem(0.2);
      color: var(--tex-def-tie2);
      padding-bottom: 0;

      ul {
        padding-left: pxToRem(23);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.available,
  &.loading {
    .quote {
      padding-bottom: pxToRem(6);

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: pxToRem(-80);
        width: 200%;
        border-bottom: $border-0;
      }
    }
  }

  &.loading {
    margin-bottom: 0;
  }
}

.quote {
  grid-area: 💰;
  position: relative;
}

.quoteAmount,
.quoteRate {
  font-weight: 800;
  sub {
    @include fontSize(14, 18);
    letter-spacing: pxToRem(0.2);
    font-weight: normal;
    vertical-align: baseline;
  }
}

.quoteAmount {
  margin-bottom: pxToRem(2);
}

.quoteAmountSkeleton,
.quoteRateSkeleton {
  :global {
    .MuiSkeleton-text {
      height: pxToRem(22);
      transform: scale(1);
    }
  }
}

.quoteAmountSkeleton {
  width: pxToRem(175);
}

.quoteRateSkeleton {
  width: pxToRem(84);
  margin: pxToRem(6) 0 pxToRem(10) 0;
}

.feesSkeleton {
  grid-area: 🎛;
  margin: pxToRem(12) pxToRem(2) pxToRem(11) 0;
  display: grid;
  grid-template-columns: auto min-content;
  gap: pxToRem(8);

  :global {
    .MuiSkeleton-text {
      height: pxToRem(16);
      transform: scale(1);
    }
  }

  dt {
    width: pxToRem(97);
  }
  dd {
    min-width: pxToRem(56);
  }
}

.logo {
  grid-area: 🌅;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.fees {
  grid-area: 🎛;
  margin: 0;
  @include fontSize(14, 18);
  letter-spacing: pxToRem(0.2);
  display: grid;
  grid-template-columns: auto min-content;
  gap: pxToRem(4);
  padding-bottom: pxToRem(4);
  padding-top: pxToRem(6);

  dt {
    font-weight: normal;
  }
  dd {
    min-width: pxToRem(56);
    font-weight: 800;
    margin: 0;
    text-align: right;
  }
}

.breakdown {
  grid-area: 🔍;
}

@include md {
  .root {
    padding-right: 0;

    grid-template:
      '💰 🎛 🌅' auto
      '💰 🎛 🔍' auto / pxToRem(220) 1fr pxToRem(200);
    row-gap: 0;

    &.unavailable {
      grid-template:
        '💰 🎛 🌅' auto
        '💰 🎛 🔍' auto / pxToRem(180) 1fr pxToRem(100);
      grid-gap: pxToRem(16);

      .fees {
        padding-top: 0;
      }
    }

    &.available,
    &.loading {
      .quote {
        padding-right: pxToRem(16);
        padding-bottom: 0;
        &:before {
          bottom: initial;
          left: initial;
          right: 0;
          top: pxToRem(-32);
          width: 0;
          height: 200%;
          border-bottom: none;
          border-right: $border-0;
        }
      }

      .fees,
      .feesSkeleton {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: pxToRem(28);
        direction: rtl;
        column-gap: pxToRem(8);

        dt {
          text-align: left;
        }
        dd {
          text-align: left;
          margin: 0;
        }
      }

      .feesSkeleton {
        margin: 0;
        justify-content: end;
      }
    }

    &.loading {
      margin-bottom: pxToRem(4);
    }

    .quoteAmountSkeleton,
    .quoteRateSkeleton {
      width: pxToRem(180);

      :global {
        .MuiSkeleton-text {
          height: pxToRem(25.5);
        }
      }
    }

    .quoteRateSkeleton {
      margin: pxToRem(13) 0 0 0;
    }
  }

  .quoteAmount,
  .quoteRate {
    font-weight: 800;

    sub {
      @include fontSize(17, 24);
      letter-spacing: initial;
    }
  }

  .fees {
    @include fontSize(15, 20);
    letter-spacing: initial;
  }

  .breakdown {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
