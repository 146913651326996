@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/helpers' as *;

$pillBorder: pxToRem(2) solid $color-neutral-100;

.optionsContainer {
  display: block;
  text-align: center;
  padding: 0;
}

.option {
  text-align: left;
  border-radius: pxToRem(99);
  border: $pillBorder;
  background: var(--bac-tie1);
  padding: pxToRem(4) pxToRem(8);
  display: inline-flex;
  gap: pxToRem(4);
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: pxToRem(4);

  .icon {
    width: pxToRem(16);
    height: pxToRem(16);
    color: $color-sentimental-success-500;
  }
}

.text {
  text-transform: uppercase;
  @include fontSize(12, 16);
  font-weight: 900;
  letter-spacing: pxToRem(1.2);
}

@include lg {
  .optionsContainer {
    flex-direction: row;
    width: fit-content;
    margin: auto;
  }
}
