@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  @include sectionContentHubArticle();
  @include sectionVerticalSpacing();
}

.title {
  margin: 0 0 pxToRem(24);
  @include fontSize(26, 32);
  color: var(--tex-def-tie1);
}

.button {
  margin: pxToRem(16) 0 0;
  width: 100%;
}

.messageContainer {
  display: flex;
  gap: pxToRem(8);
  align-items: center;
}

.message {
  margin: 0;
  @include fontSize(17, 24);
  font-weight: 400;
  color: var(--tex-def-tie1);
}

svg.iconTick {
  color: $color-sentimental-success-500;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
  margin-top: pxToRem(16);
  @include fontSize(14, 18);
  color: var(--tex-def-tie1);
}

.legal {
  a {
    color: inherit;
    text-decoration: underline;
    font-weight: 800;
  }
}

@include md {
  .title,
  .footer {
    text-align: center;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: pxToRem(16);
  }

  .messageContainer {
    justify-content: center;
  }

  .button {
    margin: 0;
    max-width: max-content;
    height: pxToRem(60);
  }
}
