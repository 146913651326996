@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

@use 'src/theme/helpers' as *;

.watchlist {
  z-index: 100;
  cursor: pointer;
  border: none;
  background: transparent;
  width: pxToRem(40);
  height: pxToRem(40);
  transition: background ease-in-out 100ms;
}

.watchlistfilled :global(.icon) {
  color: var(--pin-500);
}

.watchlistundobutton {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--tex-def-tie1);
}
