@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/helpers' as *;

//Box shadow styles for car images
$car-background-box-shadow1:
  0 pxToRem(1.947) pxToRem(7.787) 0 rgba(0, 0, 0, 0.16),
  0 0 0 0 rgba(0, 0, 0, 0.04);
$car-background-box-shadow2:
  0 pxToRem(1.893) pxToRem(7.571) 0 rgba(0, 0, 0, 0.16),
  0 0 0 0 rgba(0, 0, 0, 0.04);
$car-box-shadow:
  0 pxToRem(3) pxToRem(3) pxToRem(-2) rgba(150, 147, 189, 0.4),
  0 pxToRem(2) pxToRem(1) pxToRem(-1) rgba(62, 60, 94, 0.1);

.root {
  background: var(--bac-tie1);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.imageContainer {
  padding: pxToRem(20) pxToRem(16);
  width: 100%;
  aspect-ratio: 16 / 9;
}

.carImageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  aspect-ratio: 16 / 9;
  isolation: isolate;

  &:before,
  &:after {
    content: '';
  }

  &:before,
  &:after,
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--cor_rad-lg);
    background: var(--gradient-product-placeholder);
    box-shadow: $car-box-shadow;
  }

  &:before {
    transform: rotate(3.022deg);
  }

  &:after {
    transform: rotate(-4.029deg);
  }

  .image {
    z-index: 1;
    transform: rotate(-1.177deg);
    object-fit: cover;
  }
}

.contentContainer {
  padding: pxToRem(16) pxToRem(24);
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);
  justify-content: space-between;
  flex: 1 1 0;
}

.details {
  display: grid;
  grid-template:
    '🚗 😀' auto
    '🚗 💰' auto / auto auto;
  column-gap: pxToRem(16);
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.detailsModel {
  grid-area: 🚗;
  align-self: center;
  @include fontSize(20, 28);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.detailsPriceLabel {
  grid-area: 😀;
  text-align: right;
  align-self: flex-end;
  @include fontSize(12, 16);
  font-weight: 400;
  letter-spacing: pxToRem(0.2);
  color: var(--tex-def-tie2);
}

.detailsPrice {
  grid-area: 💰;
  text-align: right;
  @include fontSize(26, 32);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.cta {
  width: 100%;
}
