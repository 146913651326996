@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/helpers' as *;

.root {
  width: 100%;
}

.card {
  padding: pxToRem(16);
  border-radius: var(--cor_rad-lg);
  border: $border-2;
  background: var(--bac-tie1);
}

.lineBreak {
  border-top: $border-0;
  padding: pxToRem(4) 0;
  margin: pxToRem(16) 0;
}

@include md {
  .root {
    max-width: pxToRem(900);
  }

  .lineBreak {
    margin: pxToRem(24) pxToRem(16);
  }
}
