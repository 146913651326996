@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.root {
  border-radius: var(--cor_rad-lg);
  overflow: hidden;
  border: $border-0;
}

.contentWrapper {
  padding: pxToRem(16);
  background: var(--for-neu-tie3);
  border-bottom: $border-0;
}

.contentContainer {
  display: block;
}

.content {
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);
}

.buildYearContainer {
  @include fontSize(17, 24);
  font-weight: 400;

  p {
    margin: 0;
  }
}

.buildYearTitle {
  text-transform: uppercase;
  @include fontSize(12, 16);
  font-weight: 900;
  color: var(--tex-def-tie2);
}

.carDetailsContainer {
  display: flex;
  gap: pxToRem(16);

  p {
    margin: 0;
  }
}

.carModel {
  @include fontSize(17, 24);
  font-weight: 800;
}

.carImage {
  img {
    border-radius: var(--cor_rad-xs);
  }
}

.carVariant {
  @include fontSize(14, 18);
}

.browseCarButtonContainer {
  display: grid;
  grid-auto-flow: row;
  gap: pxToRem(8);
}

.submitButtonContent {
  @include fontSize(15, 18);
  padding: pxToRem(11) pxToRem(12);
}

.browseCarButtonLabel {
  display: flex;
  align-items: center;
  gap: pxToRem(8);
}

.subContentCarDetails {
  @include fontSize(14, 18);
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: pxToRem(8);
  margin-top: pxToRem(17);

  p {
    margin: 0;
  }

  .submitQuoteLink {
    @include fontSize(15, 20);
    font-weight: 800;
  }
}

.createdAt {
  color: rgba(21, 13, 44, 0.7);
  @include fontSize(14, 18);
}

.financeContainer {
  padding: pxToRem(16);
  background-color: var(--bac-tie1);
}

.quoteDetailsBlock {
  display: flex;
  justify-content: space-between;
}

.quoteDetailsTitle {
  margin: 0;
  text-transform: uppercase;
  @include fontSize(12, 16);
  font-weight: 900;
  color: var(--tex-def-tie2);
}

.repaymentAmountTitleContainer {
  display: flex;
  gap: pxToRem(8);
  align-items: center;
}

.repaymentAmountTitle {
  margin: 0;
  text-transform: uppercase;
  @include fontSize(12, 16);
  font-weight: 900;
  color: var(--tex-def-tie2);
}

.discountContainer {
  margin-right: pxToRem(16);
  display: flex;
  gap: pxToRem(4);
  height: pxToRem(30);
  padding: pxToRem(6) pxToRem(18);
  background: $color-brandPrimary-100;
  color: var(--tex-inv-tie1);
  border-radius: pxToRem(29);

  p {
    margin: 0;
    @include fontSize(12, 18);
    font-weight: 600;
    text-wrap: nowrap;
  }
}

.discountValue {
  @include fontSize(26, 32);
  font-weight: 800;
  color: $color-brandPrimary-100;
  margin-right: pxToRem(4);
}

.valuesContainer {
  margin-top: pxToRem(16);
  @include fontSize(17, 24);

  p {
    margin: 0;
  }

  .weeklyValue {
    @include fontSize(26, 32);
    font-weight: 800;
    margin-right: pxToRem(4);
  }
}

.value {
  @include fontSize(20, 28);
  font-weight: 800;
  margin-right: pxToRem(4);
}

.oldValues {
  text-decoration: line-through;
  font-weight: 400;
}

.buttonLink {
  margin-top: pxToRem(8);

  .buttonLinkContent {
    @include fontSize(14, 18);
    font-weight: 800;
  }
}

.buttonContainer {
  display: flex;
  margin-top: pxToRem(24);
  flex-direction: column;
  gap: pxToRem(8);
}

.iconSearch {
  width: pxToRem(24);
  height: pxToRem(24);
}

.preApprovalButtonLabel {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: pxToRem(4);
}

.financeImage {
  display: none;
}

.icon {
  width: pxToRem(43);
  height: pxToRem(17);
}

@include sm {
  .contentWrapper {
    display: flex;
    justify-content: space-between;
  }

  .subContentCarDetails {
    flex-direction: column;
    margin: 0;
    gap: 0;
  }

  .content {
    flex-direction: row;
    gap: pxToRem(44);
  }

  .value {
    @include fontSize(26, 32);
  }

  .discountContainer {
    position: static;
  }

  // This style is used to override the inline style (width and heigh) of NextJS Image
  .financeImage {
    display: block;

    span {
      width: pxToRem(128) !important;
      height: pxToRem(32) !important;
    }
  }

  .buttonContainer {
    justify-content: flex-end;
    flex-direction: row;
    gap: pxToRem(16);
  }

  .button {
    padding: pxToRem(8);
  }

  .preApprovalButtonContent {
    padding: pxToRem(8) pxToRem(12);
  }

  .browseCarButtonContainer {
    grid-auto-flow: column;
    gap: pxToRem(16);
  }

  .contentContainer {
    display: flex;
    justify-content: space-between;
  }

  .financeImageMobile {
    display: none;
  }
}

.preApprovalModalFooterContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: pxToRem(20);
}
