@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

@mixin contentBefore {
  content: '';
  height: 100%;
  border-left: pxToRem(1) solid $color-neutral-300;
  padding-left: pxToRem(16);
}

.root {
  max-width: pxToRem(1280);
  margin: auto;
}

.content {
  @include sectionContentHubArticle();
  padding-top: pxToRem(32);
  padding-bottom: pxToRem(32);

  .breadcrumbs {
    margin-bottom: pxToRem(16);
  }
}

.imageWrapper {
  padding-bottom: pxToRem(24);
}
.videoWrapper {
  margin: auto;
  max-width: pxToRem(1196);
}

.title {
  color: var(--tex-def-tie1);
  margin: 0 0 pxToRem(16);
  @include fontSize(26, 32);
  font-style: normal;
}

.avatar {
  border-radius: 50%;
  object-fit: cover;
  width: pxToRem(32);
  height: pxToRem(32);
}

.blockInfo {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
  color: var(--tex-def-tie3);
}

.fullName {
  @include fontSize(15, 20);
  margin-left: pxToRem(11);
}

.boldText {
  @include fontSize(12, 16);
  font-style: normal;
  font-weight: 900;
  letter-spacing: pxToRem(1.2);
  text-transform: uppercase;
  color: var(--tex-def-tie3);
  text-decoration: none;
}

.heroBanner {
  position: relative;
  width: 100%;
  max-width: pxToRem(1280);
  margin: auto;
  aspect-ratio: 16/6.7;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.divInfo {
  display: flex;
  align-items: center;
  margin: 0;
}

.dateInfo::before {
  @include contentBefore;
}

.contentInfo {
  display: flex;
  gap: pxToRem(16);
}

@include sm {
  .blockInfo {
    flex-direction: row;
  }

  .articleInfo::before {
    @include contentBefore;
  }
}

@include md {
  .imageWrapper {
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
  }
  .title {
    @include fontSize(32, 42);
  }
}

@include lg {
  .title {
    @include fontSize(44, 52);
  }
}

@include xl {
  .root {
    max-width: pxToRem($max-width-xl);
  }
  .imageWrapper {
    padding-bottom: pxToRem(32);
  }
}
